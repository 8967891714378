import React, { useEffect, useState } from "react";
import { faClock, faUser } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ironing from "../image/ironing.jpg";
import { faHeart } from "@fortawesome/free-regular-svg-icons";
import { faLocation } from "@fortawesome/free-solid-svg-icons";
import "./StyleJobListing.scss";
import axios from "axios";
import search from "../image/search.png"
import category from "../image/category.png"
import slaq from "../image/slaq.png"
import styles from "./joblist.module.css"
import ReactPaginate from 'react-paginate';

export const JobListing = () => {
  const [workstate, setWorkstate] = useState(false)
  const [pricestate, setPrice] = useState(false)
  const [datestate, setDate] = useState(false)

  
  const [users,setUsers] = useState([].slice(0,1000))
  const accesstoken = JSON.parse(localStorage.getItem("userstoken"));
  const accessToken = accesstoken?.[0]?.data?.accessToken
  console.log(users);

  
        const [pageNumber, setPageNumber] = useState(0)
        const [total,setTotal]=useState(users.reduce((prev,curr) => {
            return prev + +curr.billed} , 0 ,))
        
        const usersPerPage = 6;
        const pageVisited = pageNumber * usersPerPage;
  
  const url =  "https://motaka-be.arpify.com/jobs"
  useEffect(()=>{
    axios.get(url,{
      headers: {
        Authorization: `Bearer ${accessToken}`
      }})
      .then((res)=>{
        setUsers(res.data.data)
        console.log(res.data.data);
      })
      .catch((error) => {
        if (error.response) {
          console.log("error.response", error.response);
        } else if (error.request) {
          console.log("error.request ", error.request);
        } else if (error.message) {
          console.log("error.request ", error.message);
        }
      })
  },[])
  const  displayContacts =(users.length===0 ? (<p className={styles.nodata}>No data found</p>) : users
        .slice(pageVisited, pageVisited + usersPerPage)
        ?.map((item,index) => {
          return <div key={index} className={styles.job1}>
            <img className={styles.jobimg} src={ironing} alt="" />
            <div>
              <div>
                <p className={styles.jobsector}>{item?.category}</p>
                <p className={styles.jobdescription}>{item?.description} </p>
              </div>
              <span className={styles.duretion_address}>
                    <p className={styles.duration}>
                      <FontAwesomeIcon className={styles.margin} icon={faClock} color={"#0D0D0D"} />
                      {item?.duration +" "+ "hour"} 
                    </p>
                    <p className={styles.address}>
                      <FontAwesomeIcon className={styles.margin} icon={faLocation} color={"#0D0D0D"} />
                      {item?.address}
                    </p>
                </span>
            </div>
            <div className={styles.job1w}>
                  <span>
                    <FontAwesomeIcon className={styles.jobheart} icon={faHeart} />
                  </span>
                  <p className={styles.apple}>Apply Now</p>
              </div>
        </div>}))
  

  const pageCount = Math.ceil(users.length / usersPerPage);
        const changePage = ({selected}) =>{
        setPageNumber(selected);
        }
        
        const workduration = () =>{
          if(workstate === true){
            setWorkstate(false)
          }else{
            setWorkstate(true)
          }
        }
        
        
        const price = () =>{
          if(pricestate === true){
            setPrice(false)
          }else{
            setPrice(true)
          }
        }
        const date = () =>{
          if(datestate === true){
            setDate(false)
          }else{
            setDate(true)
          }
        }
  return (
    <header>
      <div className="container">
        <main>
          
          <h2 className={styles.joblisting}>Job Listing</h2>
        </main>
        <section>
          <div className={styles.aside1}>
          <form>
            <div className={styles.relative}>
              <input className={`${styles.input} ${styles.relative}`} type="search" placeholder="Search"></input>
              <img className={styles.imgsearch} src={search}/>
            </div>
            <div className={styles.relative1}>
            <select  className={styles.inputcategory} id="cars" name="car" form="carform">
              <option value="ironing">ironing</option>
              <option value="cleaning">cleaning</option>
              <option value="repairing">repairing</option>
              <option value="plumbing">plumbing</option>
              <option value="petWalking">petWalking</option>
            </select>
            
            </div>
            
            <div className={workstate===false ? styles.workduration : styles.workdurationopen} >
              <div className={styles.workdurationdiv} onClick={workduration}>
                <p>Work Duration</p>
                <img className={styles.workdurationimg} src={workstate===false ? category : slaq}/>
              </div>
              <form className={workstate===true ? "" : styles.none}>
                <div className={styles.hourdiv}>
                  <input name="contact"  type="radio" id="1 hour" value="1 hour"/>
                  <label for="1 hour">1 hour</label>
                </div>
                <div className={styles.hourdiv}>
                  <input name="contact"  type="radio" id="2 hour" value="2 hour"/>
                  <label for="2 hour">2 hour</label>
                </div>
                <div className={styles.hourdiv}>
                  <input name="contact"  type="radio" id="3 hour" value="3 hour"/>
                  <label for="3 hour">3 hour</label>
                </div>
                <div className={styles.hourdiv}>
                  <input name="contact"  type="radio" id="4 hour" value="4 hour"/>
                  <label for="4 hour">4 hour</label>
                </div> 
                <div className={styles.hourdiv}>
                  <input name="contact"  type="radio" id="5 hour" value="5 hour"/>
                  <label for="5 hour">5 hour</label>
                </div>
                <div className={styles.hourdiv}>
                  <input name="contact"  type="radio" id="6 hour" value="6 hour"/>
                  <label for="6 hour">6 hour</label>
                </div>
                <div className={styles.hourdiv}>
                <input name="contact"  type="radio" id="7 hour" value="7 hour"/>
                  <label for="7 hour">7 hour</label>
                </div>
                <div className={styles.hourdiv}>
                <input name="contact"  type="radio" id="All hour" value="All"/>
                  <label for="ALL">All</label>
                </div>

              </form>
                
              
            </div>
            <div className={pricestate === false ? styles.price : styles.priceopen}>
              <div className={styles.pricediv} onClick={price}>
                <p>Price</p>
                <img className={styles.priceimg} src={pricestate===false ? category : slaq}/>
              </div>
              <form className={pricestate===true ? "" : styles.none}>
              <div className={styles.hourdiv}>
                  <input name="contact"  type="radio" id="10k-20k" value="10k amd- 20k amd"/>
                  <label for="10k-20k">10k amd- 20k amd</label>
                </div> 
                <div className={styles.hourdiv}>
                  <input name="contact"  type="radio" id="20k-30k" value="20k amd- 30k amd"/>
                  <label for="20k-30k">20k amd- 30k amd</label>
                </div>
                <div className={styles.hourdiv}>
                  <input name="contact"  type="radio" id="30k-40k" value="30k amd- 40k amd"/>
                  <label for="30k-40k">30k amd- 40k amd</label>
                </div>
                <div className={styles.hourdiv}>
                <input name="contact"  type="radio" id="40k-50k" value="40k amd- 50k amd"/>
                  <label for="40k-50k">40k amd- 50k amd</label>
                </div>
                <div className={styles.hourdiv}>
                <input name="contact"  type="radio" id="All hour" value="All"/>
                  <label for="ALL">All</label>
                </div>
              </form>
            </div>
            <div className={datestate === false ? styles.price : styles.priceopen}>
              <div className={styles.pricediv} onClick={date}>
                <p>Start Date</p>
                <img className={styles.priceimg} src={datestate===false ? category : slaq}/>
              </div>
              <form className={datestate===true ? "" : styles.none}>
                <input className={styles.startdate} type="date" />
              </form>
            </div>
          </form>
            
            
            
          </div>    
              
          <div className={styles.aside2}>
          {displayContacts}
          
          {users?.length>0 ? 
          <ReactPaginate 
                            previousLabel={"<"}
                            nextLabel={">"}
                            pageCount={pageCount}
                            onPageChange={changePage}
                            containerClassName={"paginationBttns"}
                            previousLinkClassName={"previousBttn"}
                            nextLinkClassName={"nextBttn"}
                            disabledClassName={"paginationDisabled"}
                            activeClassName={"paginationActive"}
                            />: ""}
            
          </div>
          
        </section>
      </div>
    </header>
  );
};
export default JobListing