    import React from 'react'
    import styles from "./rating.module.css";
    import star from "../../image/Star.jpg"
    import star1 from "../../image/Star1.jpg"
    import users from "../../image/profile/image 1.svg"

    export const Reviewlist = () => {
    return (
        <div className={styles.reviewdivlist}>
            <div className={styles.reviewdivlisttop}>
                <img className={styles.listuser} src={users}></img>
                <p className={styles.listp}>Tyler Hall</p>
                <div className={styles.liststar}>
                <img src={star}/>
                <img src={star}/>
                <img src={star}/>
                <img src={star}/>
                <img src={star1}/>
                </div>
            </div>
            <p className={styles.listdate}>29 Apr 2022</p>
            <hr className={styles.listhr} />
            <h3 className={styles.listh3}>Best Worker</h3>
            <p className={styles.listbottomp}>Lorem ipsum dolor sit amet, consectetur 
            adipiscing elit, sed do eiusmod tempor 
            incididunt ut labore et dolore magna aliqua. 
            </p>
            </div>
    )
    }
