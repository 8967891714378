import React, { useEffect, useState } from "react";
import styles from "./postajob.module.css";

import axios from "axios";

function PostJob() {

  const accesstoken = JSON.parse(localStorage.getItem("userstoken"))


  const accessToken = accesstoken?.[0]?.data?.accessToken
 
  const [category, setJobCategory] = useState("");
  const [subCategories, setSubCategory] = useState("");
  const [starttimefrom, setStartTimefrom] = useState("");
  const [starttimeto, setStartTimeto] = useState("");
  const [address, SetAddres] = useState("");
  const [salary,setSalary ] = useState("");
  const [location,setLocation ] = useState("");
  const [description,setDescription] = useState("");


  let arrstartDatefrom = starttimefrom.split("T")
  const startDate = arrstartDatefrom[0];
  const startTime = arrstartDatefrom[1];
 
  // console.log(startTime);
  let arrstartDateto = starttimeto.split("T")

  arrstartDatefrom = new Date(starttimefrom);
  arrstartDateto = new Date(arrstartDateto)
  
  let duration = Math.round(((arrstartDateto - arrstartDatefrom)/3600000))
  
  
  // console.log(duration);
  
  const initialState = {
    value1: "",
    value2: "",
    value3: "",
    value4: "",
  };
  const [special, setSpecial] = useState(initialState);
  const [special2, setSpecial2] = useState(initialState);
  const [special3, setSpecial3] = useState(initialState);
  const [special4, setSpecial4] = useState(initialState);
  const { value1 } = special;
  const { value2 } = special2;
  const { value3 } = special3;
  const { value4 } = special4;
  const handleChange1 = (e) => {
    setSpecial({ value1: e.target.value });
  };
  const handleChange2 = (e) => {
    setSpecial2({ value2: e.target.value });
  };
  const handleChange3 = (e) => {
    setSpecial3({ value3: e.target.value });
  };
  const handleChange4 = (e) => {
    setSpecial4({ value4: e.target.value });
  };
  
  let colour1 = "",
  colour2 = "",
  colour3 = "",
  colour4 = "",
  colour5 = "",
  colour6 = "",
  colour7 = "",
  colour8 = "";
if (value1.length >= "2") {
  colour2 = "#0DC46E";
}
if (value1.match(/[a-z]/)) {
  colour1 = "#0DC46E";
}
if (value2.match(/[0-9]/)) {
  colour3 = "#0DC46E";
}
if (value2.length >= "1" ) {
  
  colour4 = "#0DC46E";
}
if (value3.match(/[a-z]/)) {
  colour5 = "#0DC46E";
}
if (value3.length >= "2") {
  colour6 = "#0DC46E";
}
if (value4.match(/[a-z]/)) {
  colour7 = "#0DC46E";
}
if (value4.length >= "10") {
  colour8 = "#0DC46E";
}





// if (value1.match(/[!@#$%\^&*()+=_]/)) {
//   colour5 = "#0DC46E";
// }


//   useEffect(() => {
//     const token = JSON.parse(localStorage.getItem("userstoken"));
//     if (token) {
//         setToken("JWT " + token.access)
//     }

//     if (!token) {
//         navigate("/");
//     } else {
//         if (JSON.parse(localStorage.getItem("user"))) {
//             setUserInfo(JSON.parse(localStorage.getItem("user")));
            
//         }
//     }

    
// }, [navigate]);

  // console.log(starttimeto > starttimefrom);
  
  const postjob = {
    category,
    subCategories: "hajox",
    address,
    startDate,
    startTime: startTime + ":00",
    salary,
    description,
    duration,
  }
  
  const createJob=(e)=>{
    e.preventDefault()
    console.log(postjob);
    
    
    
    const allInput =
    location === "" ||
    starttimefrom === "" ||
    starttimeto === "" ||
    address === "" ||
    salary === "" ||
    description === "" ;
    
    if (allInput) {
      alert("Fill in all required fields");
    }
  //  console.log(postjob);
  //  console.log(duration);
   if(!allInput){
    axios
    .post("https://motaka-be.arpify.com/jobs", postjob,
     { headers: {
        Authorization: `Bearer ${accessToken}`
      }} 
    )
    .then((res) => {
      // console.log(res, 'oki ');
      
    })
    .catch((error) => {
      if (error.response) {
        console.log("error.response", error.response);
      

      } else if (error.request) {
        console.log("error.request ", error.request);
      

      } else if (error.message) {
        console.log("error.request ", error.message);
      }
    })
}
};
  
  return (
    <>
      
        <form onSubmit={createJob}>
      <h2 className={styles.postajob}>Post a Job</h2>
      <div className={styles.flex}>
      <div className={styles.block}>
          <label>Job Category</label>
          <select onChange={(e)=>setJobCategory(e.target.value)} className={styles.input} id="cars" name="car" form="carform">
          <option value="ironing">ironing</option>
          <option value="cleaning">cleaning</option>
          <option value="repairing">repairing</option>
          <option value="plumbing">plumbing</option>
          <option value="petWalking">petWalking</option>
          </select>
          
      </div>
      <div className={styles.block}>
        <label>Full Address</label>
        <input 
        minLength="2"
        maxLength="25"
        placeholder="Adress"
        type="text"
        onInput={handleChange1}
        onChange={(e) => SetAddres(e.target.value)}></input>
        
        <div className={styles.flexvalid}>
              <p>Must be</p>
              <p className={styles.redcolor} style={{ color: colour1 }}>a-z;</p>
              <p className={styles.redcolor} style={{ color: colour2 }}>min 2 char.;</p>
              
              
        </div>
      </div>
    </div>
      
      
      <div className={styles.flex}>
        <div className={styles.block}>
          <label>Time</label>
          <input  
          onChange={(e) => setStartTimefrom(e.target.value)} 
          type="datetime-local" lang="EN"
          // placeholder="from:  12/05/2022 03:00 pm" 
          
          />
          {/* <img src={time1} /> */}
        </div>
        <div className={styles.block}>
          <label> </label>
          <input min={"starttimefrom"}
          onChange={(e) => setStartTimeto(e.target.value)} 
          type="datetime-local"
          
          ></input>
          
        </div>
      </div>
      <div className={styles.flex}>
        <div className={styles.block}>
          <label>Price </label>
          <input
          onInput={handleChange2}
          minLength="1"
          maxLength="25"
          onChange={(e) => setSalary(e.target.value)} 
          type="number" placeholder="AMD"></input>
          <div className={styles.flexvalid}>
              <p>Must be</p>
              <p className={styles.redcolor} style={{ color: colour3 }}>0-9;</p>
              <p className={styles.redcolor} style={{ color: colour4 }}>min 1 char.;</p>
              
              
        </div>
        </div>
        <div className={styles.block}>
          <label> Location</label>
          <input 
          onInput={handleChange3}
          minLength="2"
          maxLength="25"
          type="text"
          onChange={(e) => setLocation(e.target.value)} 
          placeholder="You location"></input>
          <div className={styles.flexvalid}>
              <p>Must be</p>
              <p className={styles.redcolor} style={{ color: colour5 }}>a-z;</p>
              <p className={styles.redcolor} style={{ color: colour6 }}>min 2 char.;</p>
              
              
        </div>
        </div>
      </div>
      <div>
        <div className={styles.block1}>
        <label>Job Description</label>
        <textarea 
        onInput={handleChange4}
        minLength="10"
        onChange={(e) => setDescription(e.target.value)} 
        type="text" name="usrname" placeholder="Your job description"
        ></textarea>
        <div className={styles.flexvalid}>
              <p>Must be</p>
              <p className={styles.redcolor} style={{ color: colour7 }}>a-z;</p>
              <p className={styles.redcolor} style={{ color: colour8 }}>min 10 char.;</p>
              
              
        </div>
        </div>
        
      </div>
      <button  className={styles.btn}>Post a Job</button>
     </form>
    </>
      
        
    
    
  );
}
export default PostJob;
