import { HomePage } from "../components/homePage/HomePage";
import { SignUp } from "../components/signUp/SignUp";
import { SignIn } from "../components/signIn/SignIn";
import Profile from "../components/Profile/profile";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import AboutUs from "../components/About/Aboutus";
import Categories from "../components/categories/Categories";
import { Menu } from "../components/Menu/menu";
import { ForgotPassword } from "../components/ForgotPassword/ForgotPassword";
import PostJob from "../components/Profile/PostaJob/PostaJob";
import OrderHistory from "../components/Profile/orderhistory/orderhistory";
import MyWallet from "../components/Profile/MyWallet/MyWallet";
import JobListing from "../components/jobListing/JobListing";
import RatingReview from "../components/Profile/RatingReview/ratingreview";
import { MyProfile } from "../components/Profile/MyProfile/MyProfile";
import { Navigate, Redirect } from "react-router";

function AllRoutes() {
  return (
    <>
      <BrowserRouter>
        <Menu></Menu>
        <Routes>
          <Route exact path="/" element={<HomePage />} />
          <Route exact path="/aboutus" element={<AboutUs />} />
          <Route exact path="/signup" element={<SignUp />} />
          <Route
            exact
            path="/profile"
            element={
              <Profile />
              
            }
          >
            <Route exact path="jobpost" element={<PostJob />}>
              {" "}
            </Route>
            <Route exact path="ordhistory" element={<OrderHistory />}>
              {" "}
            </Route>
            <Route exact path="mywallet" element={<MyWallet />}>
              {" "}
            </Route>
            <Route exact path="rating" element={<RatingReview />}>
              {" "}
            </Route>
            <Route exact path="myprofile" element={<MyProfile />}>
              {" "}
            </Route>
            <Route exact path="/profile" element={<MyProfile />}>
              {" "}
            </Route>
          </Route>
          <Route path="/categories" element={<Categories />} />
          <Route path="/joblist" element={<JobListing />} />
          <Route path="/signin" element={<SignIn />} />
          <Route path="/forgotpassword" element={<ForgotPassword />} />
          <Route path="*" element={<Navigate to="/signin" />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}
export default AllRoutes;
