
import axios from 'axios'
import React, { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router'
import styles from "./myprofile.module.css"


  

  

export const MyProfile = () => {
  const accesstoken = JSON.parse(localStorage.getItem("userstoken"));
  const user = JSON.parse(localStorage.getItem("user"));
  const url = "https://motaka-be.arpify.com/users/me";

  const accessToken = accesstoken?.[0]?.data?.accessToken
console.log(user);
  
  const [myprofile,setMyprofile] = useState( useState(JSON.parse(localStorage.getItem('users'))))
  const [users,setUsers] = useState("") 

  const [token, setToken] = useState()
  const [UserInfo, setUserInfo] = useState("")
  const navigate= useNavigate();
  useEffect(() => {
    const token = JSON.parse(localStorage.getItem("userstoken"));
    if (token) {
        setToken("JWT " + token.access)
    }

    if (!token) {
        navigate("/");
    } else {
        if (JSON.parse(localStorage.getItem("user"))) {
            setUserInfo(JSON.parse(localStorage.getItem("user")));
            
        }
    }
    
    axios.get(url,{
      headers: {
        Authorization: `Bearer ${accessToken}`
      }})
      .then((res)=>{
        setUsers(res)
        console.log(res);
      })
      .catch((error) => {
        if (error.response) {
          console.log("error.response", error.response);
        } else if (error.request) {
          console.log("error.request ", error.request);
        } else if (error.message) {
          console.log("error.request ", error.message);
        }
      })
    
}, [navigate,accessToken]);


// console.log(users);

  
  
  
  
  return (
    <div>
        <h2 className={styles.myprofile}>My Profile</h2>
        <div className={styles.flex}>
          <div className={styles.myprofilep}>
          <p className={styles.margin}>FirstName</p>
          <p>{users?.data?.data?.firstName||user?.data?.firstName}</p>
          
          </div>
          
          <div className={styles.myprofilep}>
          <p className={styles.margin}>Lastname</p>
          <p>{users?.data?.data?.lastName||user?.data?.lastName}</p>
          </div>
          <div className={styles.myprofilep}>
          <p className={styles.margin}>Phone</p>
          <p>{users?.data?.data?.phone||user?.data?.phone}</p>
          </div>
          <div className={styles.myprofilep}>
          <p className={styles.margin}>Email</p>
          <p>{users?.data?.data?.email||user?.data?.email}</p>
          </div>
          <div className={styles.myprofilep}>
          <p className={styles.margin}>Balance</p>
          <p>{users?.data?.data?.balance ||user?.data?.balance} AMD</p>
          </div>
          <div className={styles.myprofilep}>
          <p className={styles.margin}>Users</p>
          <p>{users?.data?.data?.role?.isUser===true || user?.data?.role?.isUser===true ?  "Yes" : "No"}</p>
          </div>
          <div className={styles.myprofilep}>
          <p className={styles.margin}>Provider</p>
          <p>{users?.data?.data?.role?.isProvider===true || user?.role?.isProvider===true ?  "Yes" : "No"}</p>
          </div>
        </div>
    </div>
  )
}
