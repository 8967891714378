import "./categories.css"
function Categories() {
    
    return (
      <>
       <div className='nav_homePage'>
                    <div>Motaka LOGO</div>
                    <div>
                        <p >Categories</p>

                        <p className='aboutus'>About Us</p>
                    </div>
                    <div>
                       
                       
                    </div>
                </div>
      <div>
 <h2 className="h2About">Categories </h2>
 <div className="coming">Coming soon...</div>
      </div>
        
      </>
    );
  }
  
  export default Categories;
  