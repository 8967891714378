import axios from 'axios';
import React, { useState } from 'react';
import "./forgotpassword.scss"

export const ForgotPassword = () => {
  
  const [phone, setPhone] = useState("");
  console.log(phone);

  const forgotPassword = async (e) =>  {
    
          axios.patch("https://motaka-be.arpify.com/reset_password", phone,
        {
          

          headers: {
            "Content-Type": "application/json",
          },
        }
        .then((data) => {
          
    
          
          console.log(data);
          
        })
    
        .catch((error) => {
          if (error.response) {
            console.log("error.response", error.response);
          } else if (error.request) {
            console.log("error.request ", error.request);
          } else if (error.message) {
            console.log("error.request ", error.message);
          }
        })
      );
  };
  return (
    <div>
        <h1 className='forgoth1'>Forgot Password</h1>
        <p className='forgotp'>Enter your phone number below to receive your password reset instruction</p>
        <label className='forgotlalel'>Phone Number</label>
        <input
        placeholder='Enter your phonee number' 
        className='forgotinput'
        value={phone}
        onChange={(e) => setPhone(e.target.value)}
        ></input>
        <button onClick={forgotPassword} className='forgotbutton'>Reset Password</button>
    </div>
    
  )
}
