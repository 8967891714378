import React from 'react'
import styles from "./rating.module.css"
import star from "../../image/Star.jpg"
import star1 from "../../image/Star1.jpg"
import users from "../../image/profile/image 1.svg"
import { Reviewlist } from './Reviewlist'
export const RatingReview = () => {
  return (
    <div>
      <h2 className={styles.h2}>Rating Review</h2>
      <div className={styles.flex}>
        <p className={styles.ratingp}>Global Review</p>
        <div className={styles.star}>
          <img src={star}/>
          <img src={star}/>
          <img src={star}/>
          <img src={star}/>
          <img src={star1}/>
          <p><span>4.3</span>/5</p>
        </div>
      </div>
      <hr className={styles.hr}></hr>
      <p className={styles.review25}>25 Review</p>
      <div className={styles.reviewdiv}>
        
        <Reviewlist />
        <Reviewlist />
        <Reviewlist />
        <Reviewlist />
        
      </div>
    </div>
  )
}


export default RatingReview;