import React from "react";
import AllRoutes from "./routes/routes";
 export function App() {


  
  return (
    <>
      <AllRoutes />
    </>
  );
}

export default App;
