    import React, { useState } from 'react';
    import  "./mywallet.scss";
    import data from "./mock-wallet.json";
    import ReactPaginate from 'react-paginate';

    export const MyWallet = () => {
        
        const [contacts,setContacts] = useState(data.slice(0,50));
        const [pageNumber, setPageNumber] = useState(0)
        const [total,setTotal]=useState(contacts.reduce((prev,curr) => {
            return prev + +curr.billed} , 0 ,))
        
        const contactsPerPage = 6;
        const pageVisited = pageNumber * contactsPerPage;
        

        const  displayContacts = contacts
        .slice(pageVisited, pageVisited + contactsPerPage)
        .map((contact,tr) => {
            return (
            <tr key={tr} className="blockwb flex">
                <td className='uldesign'>{contact.walletjobname}</td>
                <td className='sentdatatb'>{contact.sentdata}</td>
                <td className='billedtb'>{contact.billed} AMD</td>
                                    
            </tr>
            )
            
        })

        const pageCount = Math.ceil(contacts.length / contactsPerPage);
        const changePage = ({selected}) =>{
        setPageNumber(selected);
        }
        console.log(contacts);

        return (
        
            <div>
            
                    
                    <div>
                        <h2 className="mywallet">My Wallet</h2>
                        <p className='invoicing'>Billing and Invoicing</p>
                        {contacts.length===0 ? <p className='nodata'>No data found</p> : (
                        <table>
                            <thead >
                                <tr className='blockw flex'>
                                    <th className='jobname'>Job Name</th>
                                    <th className='sentdata'>Sent Data</th>
                                    <th className='billed'>Billed</th>
                                </tr>
                            </thead>
                        <tbody>
                            {displayContacts}
                            <ReactPaginate 
                            previousLabel={"<"}
                            nextLabel={">"}
                            pageCount={pageCount}
                            onPageChange={changePage}
                            containerClassName={"paginationBttns"}
                            previousLinkClassName={"previousBttn"}
                            nextLinkClassName={"nextBttn"}
                            disabledClassName={"paginationDisabled"}
                            activeClassName={"paginationActive"}
                            />
                        </tbody>
                        
                        </table>
                        
                        )}
                        
                        <div className={contacts.length===0 ? "none" : 'flex totalbilled' }>
                            <p>Total Billed</p>
                            <p className='number'>{total} AMD</p>
                        </div>
                
                
                
                    </div>
                    
                    
            </div>

            )
    }

    export default MyWallet;
